<template>
<div>
  <!-- ICONS ==================== -->
  <b-col class="d-flex justify-content-center mt-2 mb-2">
    <div>
        <p class="mx-0 mb-0 mt-50 p-0 text-center text-secondary"><small c>{{ $t(`agent.list.${i18nKey}.register_1.title`).toLowerCase() }}</small></p>
        <b-iconstack shift-v="-5" class="mx-1" :variant="animationCounter==1 ? 'favorite' : ''">
          <b-icon scale="2" icon="square" />
          <b-icon scale="1" icon="diamond-fill" />
        </b-iconstack>
        <b-iconstack shift-v="-5" class="mx-1" :variant="animationCounter==2 ? 'favorite' : ''">
          <b-icon scale="2" icon="square" />
          <b-icon scale="1" icon="triangle-fill" />
        </b-iconstack>
        <b-iconstack shift-v="-5" class="mx-1" :variant="animationCounter==3 ? 'favorite' : ''">
          <b-icon scale="2" icon="square" />
          <b-icon scale="1" icon="circle-fill" />
        </b-iconstack>
        <b-iconstack shift-v="-5" class="mx-1" :variant="animationCounter==4 ? 'favorite' : ''">
          <b-icon scale="2" icon="square" />
          <b-icon scale="1" icon="diamond-fill"/>
        </b-iconstack>
    </div>
    <div class="mt-75">
      <b-icon scale="2" icon="arrow-right-short" class="ml-1 mr-2 mt-2" shift-v="-5" variant="success" />
    </div>
    <div class="mt-75">
    <p class="mx-0 mt-0 mb-0 p-0 text-center text-secondary"><small>{{ $t(`common.terms.result`) }}</small></p>
      <p class="d-block mt-0 mb-0 py-0 mx-1 monospace"  ><big><big><big><b :class="animationCounter<1? 'btn-outline-favorite': ''">
        <span v-if="animationCounter==1">1</span>
        <span v-if="animationCounter==2">2</span>
        <span v-if="animationCounter==3">3</span>
        <span v-if="![1,2,3].includes(animationCounter)">4</span>
      </b></big></big></big></p>
    </div>
  </b-col>
  <!-- =========================== -->

<b-col md="12" class="my-2">
  <custom-input
    v-if="variables"
    class="my-1"
    id="custom-input-r1"
    :possibleValues="variables"
    v-model="agent.register_1"
    advanced
  >
    <template #label>
      {{ $t(`agent.list.${i18nKey}.register_1.title`) }}
      <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter />
      <span class="float-right text-right mx-25 text-secondary">{{ $t(`agent.list.${i18nKey}.register_1.description`) }}</span>
    </template>
  </custom-input>
    <div v-else class="mb-2">
      <b-skeleton class="mb-25" width="25%" height="12px" />
      <b-skeleton class="mb-25" width="100%" height="37px" />
      <b-skeleton class="mb-25" width="25%" height="14px" />
    </div>
</b-col>

<b-col md="12" class="my-2">
  <custom-input
    v-if="variables"
    class="my-1"
    id="custom-input-rd"
    :possibleValues="variables"
    v-model="agent.register_destiny"
    advanced
  >
    <template #label>
      {{ $t(`agent.list.${i18nKey}.register_destiny.title`) }}
      <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter />
      <span class="float-right text-right mx-25 text-secondary">{{ $t(`agent.list.${i18nKey}.register_destiny.description`) }}</span>
    </template>
  </custom-input>
    <div v-else class="mb-2">
      <b-skeleton class="mb-25" width="25%" height="12px" />
      <b-skeleton class="mb-25" width="100%" height="37px" />
      <b-skeleton class="mb-25" width="25%" height="14px" />
    </div>
  </b-col>
</div>
</template>

<script>
import { BLink, BSkeleton, BFormCheckbox, BButton, BContainer, BCol, BRow, BSidebar, BIcon } from "bootstrap-vue";
import { mapActions } from "vuex";
import { v4 as uuidv4 } from "uuid";
import DefaultAgent from "./../DefaultAgent"
import VariablesPanel from "@/views/pages/middleware/MiddlewareManagement/Components/VariablesPanel.vue";
import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
import NormalizedAgent from "@/custom/class/NormalizedAgent.js";
import HelperTooltip from '@/layouts/components/HelperTooltip';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

import AgentsMixin from '../AgentsMixin'

export default {
  mixins: [AgentsMixin],

  components: {
    BButton,
    BContainer,
    BCol,
    BRow,
    BSidebar,
    VariablesPanel,
    CustomInput,
    BFormCheckbox,
    BIcon,
    BSkeleton,
    HelperTooltip,
    BLink,
    VuePerfectScrollbar,
  },
  props: {
    middlewareID: {
      type: Number,
      required: true,
    },
    agentID: {
      type: Number,
      default: undefined,
    },
    value: {
      type: NormalizedAgent,
      default: undefined
    },
    uuid: {
      type: String,
      default: uuidv4(),
    },
    width: {
      type: String,
      default: "40%"
    },
    agentBlockID: {
      type: [ Number, String ],
      default: 1,
    },
    variables:{
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      actionID: 30,
      i18nKey: 'array_length',
      defaultAgent: {
        id: null,
        enum_agent_action_id: this.actionID,
        agent_block_id: this.agentBlockID,
        register_1: {
          source: 7,
          value: ""
        },
        register_2: {
          source: 7,
          value: ""
        },
        register_destiny: {
          source: 10,
          value: ""
        },
        execution_order: 1,
        fatal_on_fail: true,
      },

      uuidMap: {},

      variant_map: null,
      isSaving: false,
      animationCounter : 0,
    };
  },
  computed: {
    agent: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    toSaveRegs() {
      return {
        register_1: true,
        register_2: false,
        register_destiny: true,
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(["insertAgent", "updateAgent", "getAgentByID"]),
    init() {
      DefaultAgent.fetchAgentByID(this)
      this.tickAnimation()
    },
    save() {
      return DefaultAgent.save(this)
    },
    validate() {
      return DefaultAgent.validate(this)
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    toggleSidebar() {
      this.$root.$emit("bv::toggle::collapse", this.uuid);
    },
    tickAnimation(){
      if (this.animationCounter == -100){
        return;
      }

      this.animationCounter++;

      if (this.animationCounter==5){
        this.animationCounter = -3
      }
      
    setTimeout(() => {
      this.tickAnimation();
    }, "800")
},
  },
};
</script>

<style lang="scss" scoped>

.sidebar-container{
  max-height: 97vh;
  overflow-y: auto !important;
}

.sidebar-fixed-header{
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.sidebar-content{
  position: relative !important;
  padding-bottom: 20px;
  margin-bottom: 70px !important;
}

.monospace{
  font-family: monospace;
}
</style>